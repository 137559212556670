<template>
	<div class="row mt-5 mb-3 tabbed-content" id="tabbed-content-scroll">
		<div class="col-12">
			<b-tabs content-class="mt-3" v-model="tabIndex">
				<b-tab v-for="(tab, i) in tab_data" :key="i" :index="i" :title="tab.title" :id="'viaturas-' + i">
					<div class="row">
						<div class="col-12 col-md-6 mb-4" v-for="(el, indx) in tab.data" :key="indx"
							 :index="indx"
							 :class="{'col-lg-6' : config.grid === 2, 'col-lg-4' : config.grid === 3, 'col-lg-12' : config.grid === 1}">
							<div class="row">
								<div class="col-12">
									<a :href="el.link" target="_blank"><img v-lazy="setImg(el.img)"
																			class="img-fluid"></a>
								</div>
							</div>
						</div>
					</div>
				</b-tab>
			</b-tabs>
		</div>
	</div>
</template>

<script>

import {BTab, BTabs}                                   from 'bootstrap-vue';
import {Hooper, Navigation as HooperNavigation, Slide} from 'hooper';

export default {
	name       : 'TabbedStaticContentV3',
	components : {
		Hooper,
		Slide,
		HooperNavigation,
		BTabs,
		BTab
	},
	props      : {
		config : {
			type     : Object,
			required : true
		}
	},
	data() {
		return {
			tab_data       : [],
			tabIndex       : 0,
			hooperSettings : {
				itemsToShow  : 1,
				mouseDrag    : false,
				centerMode   : true,
				wheelControl : false,
			},
			isLoaded       : false
		};
	},
	watch : {
		tabIndex : function (val) {
			if (this.$refs.carousel !== undefined) {
				this.$refs.carousel.forEach((e) => {
					e.restart();
				});
			}
		}
	},
	mounted() {
		this.tab_data = this.setTabs();

		if (this.$route.hash.indexOf('#viaturas-') > -1) {
			setTimeout(() => {
				let hashTag   = this.$route.hash.split('?');
				let toSplit   = hashTag[0].split('-');
				this.tabIndex = parseInt(toSplit[1]);
				let id        = `${toSplit[0].replace('#', '')}-${toSplit[1]}`;
				document.getElementById(`tabbed-content-scroll`).scrollIntoView();
				document.getElementById(`${id}___BV_tab_button__`).scrollIntoView();
			}, 500);
		}

		setTimeout(() => {
			this.isLoaded = true;
		}, 500);
	},
	methods : {
		setTabs() {
			let tabs = [];
			this.config.tab_names.forEach((e) => {
				let tab   = {};
				tab.tag   = e.tag;
				tab.title = e.title;
				tab.data  = this.config.data.filter((el) => {
					return el.tags.includes(e.tag);
				});
				tabs.push(tab);
			});

			return tabs;
		},
		setImg(img) {
			if (process.env.NODE_ENV === 'development') {
				return 'https://www.rotauto.com/assets/img/imagemOG_rotauto2020.jpg';
			}
			return `${img}`;
		},
		setBlank(link) {
			if (link === undefined || (link.indexOf('#') > -1 && link.indexOf('http') < 0)) {
				return '_self';
			}
			return '_blank';
		},
		changeVersion(val) {
			if (val !== undefined) {
				this.$eventBus.$emit('change-version', val);
			}
		}
	}
};
</script>
