<template>
  <div class="container position">
    <div class="row">
      <!--      base template-->
      <div
        class="col-12 left-side"
        v-if="config.template === 'base'"
        :class="{
          'p-0': jsonConfig.slideshow_top !== undefined,
          'col-lg-8': formData !== null && !formData.fat_form,
          'col-md-12 col-lg-6': formData !== null && formData.fat_form,
        }"
      >
        <SingleColumn
          :config="config"
          :json-config="jsonConfig"
          v-if="columnTemplate === 'single'"
        ></SingleColumn>

        <div v-if="jsonConfig.slideshow_top !== undefined">
          <slideshow-top
            :json-config="jsonConfig"
            :images="config.slideshow_top"
          ></slideshow-top>
        </div>
      </div>

      <!--      base template 2022-->
      <div
        class="col-12 left-side"
        v-if="config.template === 'base_2022'"
        :class="{
          'p-0': jsonConfig.slideshow_top !== undefined,
          'col-lg-8': formData !== null && !formData.fat_form,
          'col-md-12 col-lg-6': formData !== null && formData.fat_form,
        }"
      >
        <!--        content header v1-->
        <div
          v-if="
            jsonConfig !== null && jsonConfig.content_header_v1 !== undefined
          "
        >
          <content-header-v1
            :config="jsonConfig.content_header_v1"
            :bg-mobile="config.bg_mobile"
            :content-header-img="config.content_header_img"
          ></content-header-v1>
        </div>
        <!--        content header v2-->
        <div
          v-if="
            jsonConfig !== null && jsonConfig.content_header_v2 !== undefined
          "
        >
          <content-header-v2
            :config="jsonConfig.content_header_v2"
            :bg-mobile="config.bg_mobile"
          ></content-header-v2>
        </div>
      </div>

      <div
        class="col-12 mt-md-3 cta-bg pt-lg-3 mb-md-3 text-center d-block d-lg-none"
        id="cta1"
        v-if="jsonConfig !== null && jsonConfig.cta !== undefined"
      >
        <div class="cta">
          <p class="mt-3">
            <span class="cta_text_1" v-html="jsonConfig.cta.text1"></span>
            <span class="cta_text_2" v-html="jsonConfig.cta.text2"></span>
          </p>
        </div>
      </div>

      <div
        class="container container_after_form d-none"
        v-if="jsonConfig.container_after_form !== undefined"
        :class="{
          'd-block d-md-none':
            jsonConfig.container_after_form.active_top === true,
        }"
      >
        <div
          class="col-12 col-lg-4 pb-3 pb-md-0"
          :class="{
            'offset-md-4': jsonConfig.container_after_form.bullets.length === 1,
            'offset-md-2':
              jsonConfig.container_after_form.bullets.length === 2 && i !== 1,
            'col-lg-6':
              jsonConfig.container_after_form.size !== undefined &&
              jsonConfig.container_after_form.size == 2,
          }"
          v-for="(item, i) of jsonConfig.container_after_form.bullets"
          :key="i"
          :index="i"
        >
          <div class="row">
            <div class="col-3">
              <img :src="config.bullets[i]" alt="" width="60px" />
            </div>
            <div class="col-9">
              <h4>{{ item.title }}</h4>
              <p>{{ item.text }}</p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="d-block d-md-none"
        style="width: 100%"
        v-if="config.scroller === 1"
      >
        <div class="text-center scroller">
          <div data-aos="fade-up" data-aos-easing="ease" data-aos-offset="0">
            <a href="#form"><i class="fas fa-chevron-down fa-3x"></i></a>
          </div>
        </div>
      </div>

      <!--					start form top-->
      <div
        class="col-12 right-side"
        id="form"
        v-if="formData !== null && formData.form_placement === 'top'"
        :class="{
          'col-md-12 col-lg-4': !formData.fat_form,
          'col-md-12 col-lg-6': formData.fat_form,
        }"
        v-observe-visibility="visibilityChanged"
      >
        <contact-form
          :config="config"
          :form-data="formData"
          :setup="setup"
          :json-config="jsonConfig"
          v-observe-visibility="{
            callback: changeVisible,
            intersection: { threshold: 0.4 },
          }"
        ></contact-form>
      </div>

      <div
        class="col-12 mt-3 mt-md-3 disclaimer mb-3 mb-md-3"
        v-if="setup.disclaimer_zone === 'top'"
      >
        <p id="privacy" v-html="privacyText"></p>
        <p id="contract">
          <strong v-html="campaignContract"></strong>
        </p>
        <p id="disclaimer" v-html="setup.campaign_disclaimer"></p>
      </div>
    </div>
  </div>
</template>

<script>
import ContactForm from "@/components/shared/ContactForm";
import ContentHeaderV1 from "@/components/shared/ContentHeaderV1";
import ContentHeaderV2 from "@/components/shared/ContentHeaderV2";
import SingleColumn from "@/components/shared/SingleColumn";
import SlideshowTop from "@/components/shared/SlideshowTop";

export default {
  name: "TopZone",
  components: {
    SingleColumn,
    ContactForm,
    SlideshowTop,
    ContentHeaderV1,
    ContentHeaderV2,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
    jsonConfig: {
      type: Object,
      required: true,
    },
    privacyText: {
      type: String,
      required: true,
    },
    campaignContract: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      setup: this.config.setup,
      columnTemplate: this.config.column_template,
      isVisible: false,
    };
  },
  methods: {
    changeVisible(isVisible, entry) {
      if (document.getElementsByClassName("sticky-button").length > 0) {
        if (!isVisible && document.body.clientWidth <= 768) {
          document
            .getElementsByClassName("sticky-button")[0]
            .setAttribute("style", "display:block !important;");
        }
        if (isVisible && document.body.clientWidth <= 768) {
          document
            .getElementsByClassName("sticky-button")[0]
            .setAttribute("style", "display:none  !important;");
        }
      }
    },
    visibilityChanged(isVisible, entry) {
      this.isVisible = isVisible;
    },
  },
};
</script>
