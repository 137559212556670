<template>
	<div class="row mt-5 mb-3 tabbed-content" id="tabbed-content-scroll">
		<div class="col-12">
			<div class="row" v-if="isLoaded">
				<div class="col-12 col-md-6 mb-4" v-for="(item, indx) in items[0].item_data" :key="indx"
					 :index="indx"
					 :class="{'col-lg-6' : parseInt(config.grid) === 2, 'col-lg-4' : parseInt(config.grid) === 3, 'col-lg-12' : parseInt(config.grid) === 1}">
					<div class="row" v-if="item.image">
						<div class="col-12">
							<img :src="item.image[0]"
								 class="img-fluid">
						</div>
					</div>

					<div class="row mb-4 mt-3 car-make-model" :id="item.version_id">
						<div class="col-12">
							<h2 v-if="item.title" v-html="item.title"></h2>
							<h3 v-if="item.subtitle" v-html="item.subtitle"></h3>
						</div>
					</div>

					<div class="row mt-3 mb-4" v-if="item.description">
						<div class="col-12">
							<div v-html="item.description">
							</div>
							<div v-if="item.form_id !== null">
								<p class="select-car">
									<a :href="item.link" :target="setBlank(item.link)" rel="noopener"
									   @click="changeVersion(item.form_id)"
									   class="btn btn-primary btn-block btn-card"
									   style="padding-top:10px;font-size: 1rem; margin-top: 30px;"
									   v-html="config.detail_text_link">
										<img class="pl-1" v-lazy="config.button_img" alt=""
											 v-if="config.button_img !== undefined && config.button_img !== ''"
											 height="17px"></a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import {BTab, BTabs} from 'bootstrap-vue';


export default {
	name       : 'ProductsCardsV5',
	components : {
		BTabs,
		BTab
	},
	props      : {
		config   : {
			type     : Object,
			required : true
		},
		products : {
			type     : Array,
			required : true
		},
		type     : {
			type     : String,
			required : true
		}
	},
	data() {
		return {
			items    : [],
			tabIndex : 0,
			isLoaded : false
		};
	},
	watch : {
		tabIndex : function (val) {
			if (this.$refs.carousel !== undefined) {
				this.$refs.carousel.forEach((e) => {
					e.restart();
				});
			}
		}
	},
	mounted() {
		this.items = this.setTabs();

		if (this.$route.hash.indexOf('#viaturas-') > -1) {
			setTimeout(() => {
				let hashTag   = this.$route.hash.split('?');
				let toSplit   = hashTag[0].split('-');
				this.tabIndex = parseInt(toSplit[1]);
				let id        = `${toSplit[0].replace('#', '')}-${toSplit[1]}`;
				document.getElementById(`tabbed-content-scroll`).scrollIntoView();
				document.getElementById(`${id}___BV_tab_button__`).scrollIntoView();
			}, 500);
		}

		setTimeout(() => {
			this.isLoaded = true;
		}, 500);
	},
	methods : {
		setTabs() {
			let tabs = [];
			this.config.tab_names.forEach((e) => {
				let tab       = {};
				tab.tag       = e.tag;
				tab.title     = e.title;
				tab.item_data = this.products.filter((el) => {
					let tags = el.tags.replace(' ', '').split(',');
					return tags.includes(e.tag);
				});
				tabs.push(tab);

			});

			return tabs;
		},
		setImg(img) {
			if (process.env.NODE_ENV === 'development') {
				return 'https://www.rotauto.com/assets/img/imagemOG_rotauto2020.jpg';
			}
			return `${img}`;
		},
		setBlank(link) {
			if (link === undefined || link === null || (link.indexOf('#') > -1 && link.indexOf('http') < 0)) {
				return '_self';
			}
			return '_blank';
		},
		changeVersion(val) {
			if (val !== undefined && this.type !== 'modal') {
				this.$eventBus.$emit('change-version', val);
			}
			if (this.type === 'modal') {
				this.$eventBus.$emit('open-modal', { version: val });
			}
		},
		rotation(indx) {
			const el = this.$refs.icon;
			for (let i = 0; i < el.length; i++) {
				if (el[i].getAttribute('data-index') == indx && !el[i].classList.contains('transition')) {
					el[i].classList.add('transition');
				} else {
					el[i].classList.remove('transition');
				}
			}
		}
	}
};
</script>

<style>
.card-text {
	font-size: 0.9rem;
	min-height: 110px;
}

.accordion-text {
	font-size: 0.85rem;
	line-height: unset !important;
	font-weight: 400 !important;
}

.accordion .card {
	border: none;
}

.header-accordion {
	border: none;
	background-color: transparent;
}

.btn-accordion {
	background-color: #eee;
	border: none;
	padding-top: 5px;
	padding-bottom: 5px;
	border-radius: 5px;
	font-weight: 600;
	letter-spacing: 1px;
	text-transform: uppercase;
	font-size: 0.8rem;
}

.btn-card {
	font-size: 1rem;

}

.btn-accordion .fas.fa-chevron-down {
	padding-top: 3px;
}

.transition {
	transition: transform 0.5s ease-in-out;
	transform: rotate(180deg);
}

.trans {
	transition: transform 0.5s ease-in-out;
}
</style>
