<template>
	<div class="slideshow-top pt-md-0">
		<hooper ref="carousel" @slide="updateCarousel" :settings="hooperSettingsTop"
				:infinite-scroll="true" :auto-play="true" :play-speed="2000">
			<slide v-for="(slide, indx) in images" :key="indx" :index="indx">
				<img v-lazy="slide" alt="" class="img-fluid">
			</slide>
			<hooper-navigation slot="hooper-addons"></hooper-navigation>
		</hooper>
	</div>
</template>

<script>
import {Hooper, Navigation as HooperNavigation, Pagination as HooperPagination, Slide} from 'hooper';

import 'hooper/dist/hooper.css';

export default {
	name       : 'SlideshowTop',
	components : {
		Hooper,
		HooperNavigation,
		HooperPagination,
		Slide
	},
	props      : {
		jsonConfig : {
			type     : Object,
			required : true
		},
		images     : {
			type     : Array,
			required : true
		}
	},
	data() {
		return {
			hooperSettingsTop : {
				mouseDrag    : false,
				centerMode   : true,
				wheelControl : false,
				breakpoints  : {
					300  : {
						centerMode  : true,
						itemsToShow : 1
					},
					768  : {
						centerMode  : true,
						itemsToShow : 1
					},
					1024 : {
						centerMode  : true,
						itemsToShow : 1
					},
				}
			},
		};
	},
	methods : {
		updateCarousel(payload) {
			this.carouselData = payload.currentSlide;
		},
	}
};
</script>
