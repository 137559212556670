<template>
  <div class="row mt-5 mb-3 tabbed-content" id="tabbed-content-scroll">
    <div class="col-12">
      <b-tabs content-class="mt-3" v-model="tabIndex">
        <b-tab
          v-for="(tab, i) in items"
          :key="i"
          :index="i"
          :title="tab.title"
          :id="'viaturas-' + i"
        >
          <div
            class="row mb-4"
            v-for="(item, indx) in tab.item_data"
            :key="indx"
            :index="indx"
          >
            <div
              class="col-12 col-md-6 align-self-center"
              :class="{
                'order-md-2 order-1': !(indx % 2),
                'order-md-1 order-2': config.ordered !== undefined,
              }"
            >
              <div class="row mb-4 mt-3 car-make-model" :id="item.version_id">
                <div class="col-12">
                  <h2
                    v-if="item.title"
                    class="custom-title"
                    v-html="item.title"
                  ></h2>
                  <h3
                    v-if="item.subtitle"
                    class="custom-subtitle"
                    v-html="item.subtitle"
                  ></h3>
                  <h3
                    v-if="item.price"
                    class="custom-price"
                    v-html="item.price"
                  ></h3>
                </div>
              </div>
              <div class="row mt-3 mb-4" v-if="item.description">
                <div class="col-12">
                  <div class="specs custom-desc">
                    <div v-html="item.description"></div>
                  </div>
                  <div class="mt-4" v-if="item.form_id !== null">
                    <p class="select-car custom-btn">
                      <a
                        :href="item.link"
                        :target="setBlank(item.link)"
                        rel="noopener"
                        @click="changeVersion(item.form_id)"
                        style="
                          font-weight: 400;
                          font-size: 1rem;
                          text-decoration: none;
                        "
                        v-html="config.detail_text_link"
                      >
                        <img
                          class="pl-1"
                          v-lazy="config.button_img"
                          alt=""
                          v-if="
                            config.button_img !== undefined &&
                            config.button_img !== ''
                          "
                          height="17px"
                      /></a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-md-6"
              :class="{ 'order-md-1 order-1': !(indx % 2) }"
            >
              <div class="row" v-if="item.image">
                <div class="col-12">
                  <img :src="item.image[0]" class="img-fluid custom-img" />
                </div>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { BTab, BTabs } from "bootstrap-vue";
import { Hooper, Navigation as HooperNavigation, Slide } from "hooper";

export default {
  name: "ProductsCardsV2",
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    BTabs,
    BTab,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      tabIndex: 0,
      hooperSettings: {
        itemsToShow: 1,
        mouseDrag: false,
        centerMode: true,
        wheelControl: false,
      },
      isLoaded: false,
    };
  },
  watch: {
    tabIndex: function (val) {
      if (this.$refs.carousel !== undefined) {
        this.$refs.carousel.forEach((e) => {
          e.restart();
        });
      }
    },
  },
  mounted() {
    this.items = this.setTabs();

    if (this.$route.hash.indexOf("#viaturas-") > -1) {
      setTimeout(() => {
        let hashTag = this.$route.hash.split("?");
        let toSplit = hashTag[0].split("-");
        this.tabIndex = parseInt(toSplit[1]);
        let id = `${toSplit[0].replace("#", "")}-${toSplit[1]}`;
        document.getElementById(`tabbed-content-scroll`).scrollIntoView();
        document.getElementById(`${id}___BV_tab_button__`).scrollIntoView();
      }, 500);
    }

    setTimeout(() => {
      this.isLoaded = true;
    }, 500);
  },
  methods: {
    setTabs() {
      let tabs = [];
      this.config.tab_names.forEach((e) => {
        let tab = {};
        tab.tag = e.tag;
        tab.title = e.title;
        tab.item_data = this.products.filter((el) => {
          let tags = el.tags.replace(" ", "").split(",");
          return tags.includes(e.tag);
        });
        tabs.push(tab);
      });

      return tabs;
    },
    setImg(img) {
      if (process.env.NODE_ENV === "development") {
        return "https://www.rotauto.com/assets/img/imagemOG_rotauto2020.jpg";
      }
      return `${img}`;
    },
    setBlank(link) {
      if (
        link === undefined ||
        link === null ||
        (link.indexOf("#") > -1 && link.indexOf("http") < 0)
      ) {
        return "_self";
      }
      return "_blank";
    },
    changeVersion(val) {
      if (val !== undefined) {
        this.$eventBus.$emit("change-version", val);
      }
    },
  },
};
</script>
