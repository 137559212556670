<template>
	<div>
		<b-modal ref="custom-modal" hide-footer
				 :size="jsonConfig.modal.size !== undefined ? jsonConfig.modal.size : 'xl'">
			<div class="text-center">
				<img :src="setImg()" alt="" class="img-fluid">
			</div>

			<div class="text-center mt-3 mb-4" v-if="jsonConfig.modal.button">
				<button class="btn btn-primary" @click="closeModal" v-html="jsonConfig.modal.button_text"></button>
			</div>
		</b-modal>
	</div>
</template>

<script>
import {BModal} from 'bootstrap-vue';
import Vue      from 'vue';

Vue.component('b-modal', BModal);
export default {
	name       : 'Modal',
	components : {
		BModal
	},
	props      : {},
	data() {
		return {
			jsonConfig : this.$store.state.jsonConfig
		};
	},
	async mounted() {
		try {
			if (this.jsonConfig.modal.splash) {
				this.showModal();
			}
		} catch (error) {
			console.log(error);
		}
	},
	methods : {
		showModal() {
			setTimeout(() => {
				this.$refs['custom-modal'].show();
			});
		},
		closeModal() {
			this.$refs['custom-modal'].hide();
			let el   = document.getElementById('form');
			// Get the bounding rectangle so we can get the element position position
			let rect = el.getBoundingClientRect();
			// Scroll to the element (using x and y axis)
			window.scrollTo(rect.left, rect.top);
		},
		setImg() {
			if (window.innerWidth > 768) {
				return this.jsonConfig.modal.img_desktop;
			} else {
				return this.jsonConfig.modal.img_mobile;
			}
		}
	}
};
</script>
