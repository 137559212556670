<template>
	<div class="accordion-card mt-5">
		<h3 v-html="config.title" class="mb-4"></h3>
		<div class="row">
			<div
				:class="{'col-12 col-lg-8 offset-lg-2' : grid === 1,'col-12 col-lg-6 col-md-6' : grid === 2, 'col-md-6 col-lg-4 col-12' : grid === 3, 'col-lg-3 col-md-6 col-12' : grid === 4}"
				v-for="(item, i) in config.cards" :key="i">
				<b-card
					:title="item.card_title"
					:img-src="item.card_image"
					img-alt="Image"
					img-top
					tag="article"
					style=""
					class="mb-2"
				>
					<b-card-text class="card-text" v-html="item.card_text">
					</b-card-text>
					<div class="accordion" role="tablist" v-if="item.accordion_text.length > 0">
						<b-card no-body class="mb-3">
							<b-card-header header-tag="header" class="p-1 header-accordion" role="tab">
								<button block v-b-toggle="'accordion-'+i" class="btn-block btn-accordion"
										v-html="item.accordion_button +' '+'<i class=&quot;fas fa-chevron-down&quot;></i>'"></button>
							</b-card-header>
							<b-collapse :id="'accordion-'+i" accordion="my-accordion" role="tabpanel">
								<b-card-body class="px-0">
									<b-card-text class="accordion-text" v-html="item.accordion_text"></b-card-text>
								</b-card-body>
							</b-collapse>
						</b-card>
					</div>
					<a :href="item.card_url" class="btn btn-primary btn-block btn-card"
					   v-html="item.card_button"></a>
				</b-card>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name       : 'AccordionCards',
	components : {},
	props      : {
		config : {
			type     : Object,
			required : true,
		},
		size   : {
			type     : Number,
			required : true,
		}
	},
	data() {
		return {
			grid : this.size,
		};
	},
};
</script>
