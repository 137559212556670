<template>
	<div>
		<b-modal ref="modal-form" hide-footer
				 :size="'lg'">
			<div class="row">
				<div class="col-12 pr-5 pl-5 pt-3 pb-2">
					<h2 v-html="formData.form_title" class="form-title text-center" v-if="!success"></h2>
					<h2 v-html="formData.form_subtitle" class="form-sub-title text-center" v-if="!success"></h2>
					<contact-form
						:config="config"
						:form-data="formData"
						:setup="setup"
						:json-config="jsonConfig"
						:zone="'modal'"
					></contact-form>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
import ContactForm from '@/components/shared/ContactForm';
import {BModal}    from 'bootstrap-vue';
import Vue         from 'vue';

Vue.component('b-modal', BModal);

export default {
	name       : 'FormModal',
	components : {
		BModal, ContactForm
	},
	props      : {
		config     : {
			type     : Object,
			required : true
		},
		formData   : {
			type     : Object,
			required : true
		},
		setup      : {
			type     : Object,
			required : true
		},
		jsonConfig : {
			type     : Object,
			required : true,
		},
	},
	data() {
		return {
			success : false
		};
	},
	async mounted() {
		this.$eventBus.$on('success-form', (data) => this.success = data);
	},
	created() {
		this.$eventBus.$on('open-modal', (data) => {
			this.$store.dispatch('formVersion', data);
			this.showModal();
		});
	},
	methods : {
		showModal() {
			setTimeout(() => {
				this.$refs['modal-form'].show();
			});
		},
		closeModal() {
			this.$refs['modal-form'].hide();
			let el   = document.getElementById('form');
			let rect = el.getBoundingClientRect();
			window.scrollTo(rect.left, rect.top);
		}
	}
};
</script>
