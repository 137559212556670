<template>
  <footer class="footer-lubrigaz">
    <cookie-law
      theme="mytheme"
      :position="mobile ? 'bottom' : 'top'"
      :transition-name="'fade'"
      ref="cookieLaw"
      v-if="timeout"
    >
      <div style="width: 100%; padding: 30px; font-size: 0.85rem">
        <div style="max-width: 1140px; margin: 0 auto">
          <h2
            style="font-size: 1.5rem"
            class="pb-3 pt-2"
            v-if="cookiesText !== ''"
          >
            {{ cookiesTitle }}
          </h2>
          <p style="line-height: 1.5rem" v-html="setCookiesText()"></p>
          <p class="text-right">
            <a
              class="ml-3"
              @click="redirect()"
              style="font-size: 0.85rem; font-weight: 400; cursor: pointer"
            >
              <span>Não Aceito</span>
            </a>
            <button
              class="ml-3 btn btn-primary"
              @click="setCookies"
              style="font-size: 14px; font-weight: 700; padding: 5px 10px"
            >
              <span>Aceito</span>
            </button>
          </p>
        </div>
      </div>
    </cookie-law>
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-6 col-12 pt-5">
          <ul class="footer-menu">
            <li>
              <a href="https://www.lubrigaz.pt/" target="_blank" rel="noopener"
                >Homepage</a
              >
            </li>
            <li>
              <a
                href="https://www.lubrigaz.pt/modelos-volkswagen"
                target="_blank"
                rel="noopener"
                >Modelos</a
              >
            </li>
            <li>
              <a
                href="https://www.lubrigaz.pt/viaturas-volkswagen-empresas"
                target="_blank"
                rel="noopener"
                >Empresas</a
              >
            </li>
            <li>
              <a
                href="https://www.lubrigaz.pt/campanhas-volkswagen"
                target="_blank"
                rel="noopener"
                >Campanhas</a
              >
            </li>
            <li>
              <a
                href="https://www.lubrigaz.pt/usados-volkswagen"
                target="_blank"
                rel="noopener"
                >Usados e Semi-novos</a
              >
            </li>
            <li>
              <a
                href="https://www.lubrigaz.pt/servicos-oficina-volkswagen"
                target="_blank"
                rel="noopener"
                >Oficina</a
              >
            </li>
            <li>
              <a
                href="https://www.lubrigaz.pt/pecas-originais-volkswagen"
                target="_blank"
                rel="noopener"
                >Peças</a
              >
            </li>
            <li>
              <a
                href="https://www.lubrigaz.pt/quem-somos"
                target="_blank"
                rel="noopener"
                >Quem somos</a
              >
            </li>
            <li>
              <a
                href="https://www.lubrigaz.pt/contactos"
                target="_blank"
                rel="noopener"
                >Contactos</a
              >
            </li>
          </ul>
        </div>
        <div class="col-md-3 col-12 pt-5 d-lg-block d-none">
          <div class="row">
            <div class="col-1 p-0 footer-contactos-icon">
              <i class="fas fa-home fa-lg iconColor"></i>
            </div>
            <div class="col-11 footer-contactos-text">
              <h5>Lubrigaz Leiria</h5>
              <p>Carvoeiros - Santa Eufémia<br />2420-500 Leiria</p>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-1 p-0 footer-contactos-icon">
              <i class="fas fa-phone fa-lg iconColor"></i>
            </div>
            <div class="col-11 footer-contactos-text">
              <h5>Telefone</h5>
              <p>
                <a
                  href="tel:244830000"
                  onclick="dataLayer.push({
                                              event          : 'contact_click',
                                              category       : 'lead_phone',
                                              action         : '',
                                              label          : 'Lubrigaz - Leiria',
                                              value          : 0,
                                              })"
                  >244 830 000</a
                >
              </p>
              <p style="margin: -15px 0 15px 0; font-size: 0.7rem">
                (custo de chamada para rede fixa nacional)
              </p>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-1 p-0 footer-contactos-icon">
              <i class="far fa-envelope fa-lg iconColor"></i>
            </div>
            <div class="col-11 footer-contactos-text">
              <h5>E-mail</h5>
              <p>
                <a
                  href="mailto:geral@lubrigaz.pt"
                  onclick="dataLayer.push({
                                              event          : 'contact_click',
                                              category       : 'lead_email',
                                              action         : '',
                                              label          : 'Lubrigaz - Leiria',
                                              value          : 0,
                                              })"
                  >geral@lubrigaz.pt</a
                >
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-12 pt-5 d-lg-block d-none">
          <div class="row">
            <div class="col-1 p-0 footer-contactos-icon">
              <i class="fas fa-home fa-lg iconColor"></i>
            </div>
            <div class="col-11 footer-contactos-text">
              <h5>Lubrigaz Caldas da Rainha</h5>
              <p>
                R. Dr. Artur Figueira Rego, nº100<br />
                Lavradio (Estrada da Tornada)<br />
                2500-911 Caldas da Rainha
              </p>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-1 p-0 footer-contactos-icon">
              <i class="fas fa-phone fa-lg iconColor"></i>
            </div>
            <div class="col-11 footer-contactos-text">
              <h5>Telefone</h5>
              <p>
                <a
                  href="tel:262840510"
                  onclick="dataLayer.push({
                                              event          : 'contact_click',
                                              category       : 'lead_phone',
                                              action         : '',
                                              label          : 'Lubrigaz - Leiria',
                                              value          : 0,
                                              })"
                  >262 840 510</a
                >
              </p>
              <p style="margin: -15px 0 15px 0; font-size: 0.7rem">
                (custo de chamada para rede fixa nacional)
              </p>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-1 p-0 footer-contactos-icon">
              <i class="far fa-envelope fa-lg iconColor"></i>
            </div>
            <div class="col-11 footer-contactos-text">
              <h5>E-mail</h5>
              <p>
                <a
                  href="mailto:geral@lubrigaz.pt"
                  onclick="dataLayer.push({
                                              event          : 'contact_click',
                                              category       : 'lead_email',
                                              action         : '',
                                              label          : 'Lubrigaz - Leiria',
                                              value          : 0,
                                              })"
                  >geral@lubrigaz.pt</a
                >
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12 pt-5 list3">
          <div class="row">
            <div class="col-1 p-0">
              <i class="far fa-clock fa-lg iconColor"></i>
            </div>
            <div class="col-11">
              <h4>Horário</h4>
              <div class="footer-contactos-text">
                <p class="mb-0">
                  <b>Segunda a sexta</b><br />
                  09h às 13h / 14h às 19h
                </p>
                <p class="mb-0">
                  <b>Sábado</b><br />
                  10h às 13h / 14h30 às 18h
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row d-flex justify-content-center list2 pt-5">
        <div>
          <a
            href="https://www.lubrigaz.pt/termos-condicoes"
            target="_blank"
            rel="noopener"
            >Política de Privacidade</a
          >
          <span class="divisor"> | </span>
          <a
            href="https://www.lubrigaz.pt/termos-condicoes#politica-cookies"
            target="_blank"
            rel="noopener"
            >Política de Cookies</a
          >
          <span class="divisor"> | </span>
          <a
            href="https://www.lubrigaz.pt/termos-condicoes#arbitragem-consumo"
            target="_blank"
            rel="noopener"
            >Arbritragem de Consumo</a
          >
          <span class="divisor"> | </span>
          <a
            href="https://www.lubrigaz.pt/termos-condicoes#livro-reclamacoes"
            target="_blank"
            rel="noopener"
            >Livro de Reclamações Electrónico</a
          >
          <span class="divisor"> | </span>
          <a
            href="https://www.lubrigaz.pt/termos-condicoes#sistema-denuncia"
            target="_blank"
            rel="noopener"
            >Sistema de Denúncia</a
          >
          <span class="divisor"> | </span>
          <a
            href="https://www.lubrigaz.pt/termos-condicoes#politica-prevencao-corrupcao"
            target="_blank"
            rel="noopener"
            >Política de Prevenção de Corrupção</a
          >
        </div>
      </div>

      <hr />

      <div class="d-flex justify-content-center list p-3">
        <div class="social-footer-title">Siga-nos nas redes sociais!</div>
      </div>

      <div class="d-flex justify-content-center list pb-3 footer-social">
        <div>
          <a
            class="nav-link"
            target="_blank"
            rel="noopener"
            href="https://www.facebook.com/lubrigaz.SA/"
            ><i class="fab fa-facebook-square fa-lg"></i
          ></a>
        </div>
        <div>
          <a
            class="nav-link"
            target="_blank"
            rel="noopener"
            href="https://www.instagram.com/lubrigazvolkswagen/"
            ><i class="fab fa-instagram fa-lg"></i
          ></a>
        </div>
      </div>
    </div>
    <div class="container-fluid sub-footer">
      <div class="container">
        <div
          class="d-flex flex-md-row flex-sm-column flex-column justify-content-md-between"
        >
          <div>© Lubrigaz</div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import "@/assets/styles/scss/_footer-lubrigaz.scss";
import footerMixinUtils from "@/common/footer-utils";
import * as moment from "moment";
import CookieLaw from "vue-cookie-law";

export default {
  name: "FooterLubrigaz",
  components: { CookieLaw },
  mixins: [footerMixinUtils],
  props: {
    config: {
      type: Object,
      required: true,
    },
    setup: {
      type: Object,
      required: true,
    },
    sellerId: {
      type: Number,
      required: true,
    },
    forcePrivacyPages: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      year: moment().year(),
      url: this.setup.company_url,
      name: this.setup.company_name,
      logo: this.setup.footer_logo_agency,
      mobile: this.isMobile(),
      timeout: false,
      cookiesText: this.setup.cookies_text,
      cookiesTitle:
        this.setup.cookies_title !== null
          ? this.setup.cookies_title
          : "Política de Cookies",
    };
  },
  mounted() {
    if (
      this.$cookies.get(`${this.sellerId}-autoleads-cookie`) !== null &&
      this.$cookies.get(`${this.sellerId}-autoleads-cookie`).accepted &&
      this.$cookies.get(`${this.sellerId}-autoleads-cookie`).domain ===
        window.location.hostname
    ) {
      setTimeout(() => {
        if (this.$refs.cookieLaw !== undefined) this.$refs.cookieLaw.close();
      }, 500);
    } else {
      this.timeout = true;
      setTimeout(() => {
        this.$refs.cookieLaw.open();
      }, 500);
    }
  },
  methods: {
    setCookiesText() {
      return this.helpers.setCookiesText(
        this.sellerId,
        this.cookiesText,
        this.config
      );
    },
    redirect() {
      window.location.href = this.setup.company_url;
    },
    setCookies() {
      this.$refs.cookieLaw.close();
      this.$eventBus.$emit("set-cookies-gtm", true);
    },
  },
};
</script>
