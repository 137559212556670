<template>
	<div class="d-md-flex d-block justify-content-md-center mb-3 mt-3">
		<div class="m-1">
			<a class="btn btn-primary btn-block pl-5 pr-5" href="#form" v-html="jsonConfig.button_text"
			   v-if="jsonConfig.show_button">
			</a>
		</div>
		<div class="m-1 mt-3 mt-md-1">
			<button class="btn btn-primary btn-block pl-5 pr-5" v-html="jsonConfig.phone_text"
					v-if="jsonConfig.show_phone"
					:class="{'show-phone' : showPhone}"
					@click="setPhone(jsonConfig.phone)"></button>
			<a class="mt-0 btn-phone-button btn btn-primary btn-block pl-5 pr-5" :href="'tel:'+ jsonConfig.phone"
			   v-if="showPhone"
			   @click="triggerGTM"
			   v-html="formatPhoneNumber(jsonConfig.phone)"></a>
		</div>
	</div>
</template>

<script>
export default {
	name  : 'PhoneButtonCta',
	props : {
		jsonConfig : {
			type     : Object,
			required : true
		}
	},
	data() {
		return {
			showPhone : false,
		};
	},
	computed : {},
	methods  : {
		formatPhoneNumber(str) {
			//Filter only numbers from the input
			let cleaned = ('' + str).replace(/\D/g, '');
			//Check if the input is of correct length
			let match   = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/);
			console.log(match[1] + ' ' + match[2] + ' ' + match[3]);
			if (match) {
				return match[1] + ' ' + match[2] + ' ' + match[3];
			}

			return null;
		},
		setPhone() {
			this.$gtm.trackEvent({
				event          : 'viuTelefone', // Event type [default = 'interaction'] (Optional)
				category       : 'viuTelefone',
				action         : 'viuTelefone',
				label          : 'viuTelefone',
				eventID        : this.$store.state.alid,
				value          : 0,
				noninteraction : false // Optional
			});
			window.alp.trackEvent({ 'event' : 'viuTelefone' });
			this.showPhone = true;
		},
		triggerGTM() {
			this.$gtm.trackEvent({
				event          : 'phoneClicked', // Event type [default = 'interaction'] (Optional)
				category       : 'phoneClicked',
				action         : 'phoneClicked',
				label          : 'phoneClicked',
				eventID        : this.$store.state.alid,
				value          : 0,
				noninteraction : false // Optional
			});
			window.alp.trackEvent({ 'event' : 'phoneClicked' });
		},
	}
};
</script>
