<template>
	<footer class="footer" id="footer">
		<cookie-law theme="mytheme" :position="mobile ? 'bottom' : 'top'" :transition-name="'fade'" ref="cookieLaw"
					v-if="timeout">
			<div style="width: 100%; padding: 30px;font-size: 0.85rem">
				<div style="max-width: 1140px; margin: 0 auto;">
					<h2 style="font-size: 1.5rem;" class="pb-3 pt-2" v-if="cookiesText !== ''">
						{{ cookiesTitle }}
					</h2>
					<p style="line-height: 1.5rem;" v-html="setCookiesText()">
					</p>
					<p class="text-right">
						<a class="ml-3" @click="redirect()"
						   style="font-size: 0.85rem;font-weight: 400; cursor: pointer;">
							<span>Não Aceito</span>
						</a>
						<button class="ml-3 btn btn-primary" @click="setCookies"
								style="font-size: 14px;font-weight: 700;padding: 5px 10px;">
							<span>Aceito</span>
						</button>
					</p>
				</div>
			</div>
		</cookie-law>
		<div class="col-12 pt-1 pb-2">
			<div class="d-flex justify-content-between">
				<div>
					&copy; {{ year }} <span class="footer-yellow"><a v-bind:href="url" target="_blank" rel="noopener">
					{{ name }}</a></span>
				</div>
				<div>
					<a href="https://rotauto.pro/" target="_blank" rel="noopener"><img
						:src="require(`@/assets/images/Logo_Agency-01.svg`)" alt="" height="25px"
						v-if="logo === 'white'"></a>
					<a href="https://rotauto.pro/" target="_blank" rel="noopener"><img
						:src="require(`@/assets/images/Logo_Agency_dark-01.svg`)" alt="" height="25px"
						v-if="logo === 'black'"></a>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import footerMixinUtils from '@/common/footer-utils';
import * as moment      from 'moment';
import CookieLaw        from 'vue-cookie-law';

export default {
	name       : 'FooterAlpine',
	components : { CookieLaw },
	mixins     : [footerMixinUtils],
	props      : {
		config                  : {
			type     : Object,
			required : true,
		},
		setup                   : {
			type     : Object,
			required : true,
		},
		sellerId                : {
			type     : Number,
			required : true
		},
		forcePrivacyPages       : {
			type     : Boolean,
			required : false
		},
		forceFormMarketingTexts : {
			type     : Boolean,
			required : true
		}
	},
	data() {
		return {
			year         : moment().year(),
			url          : this.setup.company_url,
			name         : this.setup.company_name,
			logo         : this.setup.footer_logo_agency,
			mobile       : this.isMobile(),
			timeout      : false,
			cookiesText  : this.setup.cookies_text,
			cookiesTitle : this.setup.cookies_title !== null ? this.setup.cookiesTitle : 'Política de Cookies'
		};
	},
	mounted() {
		if (this.$cookies.get(`${this.sellerId}-autoleads-cookie`) !== null && this.$cookies.get(`${this.sellerId}-autoleads-cookie`).accepted && this.$cookies.get(`${this.sellerId}-autoleads-cookie`).domain === window.location.hostname) {
			setTimeout(() => {
				if (this.$refs.cookieLaw !== undefined) this.$refs.cookieLaw.close();
			}, 500);
		} else {
			this.timeout = true;
			setTimeout(() => {
				this.$refs.cookieLaw.open();
			}, 500);
		}
	},
	methods : {
		setCookiesText() {
			return this.helpers.setCookiesText(this.sellerId, this.cookiesText);
		},
		redirect() {
			window.location.href = this.setup.company_url;
		},
		setCookies() {
			this.$refs.cookieLaw.close();
			this.$eventBus.$emit('set-cookies-gtm', true);
		}
	}
};
</script>
