var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container position"},[_c('div',{staticClass:"row"},[(_vm.config.template === 'base')?_c('div',{staticClass:"col-12 left-side",class:{
        'p-0': _vm.jsonConfig.slideshow_top !== undefined,
        'col-lg-8': _vm.formData !== null && !_vm.formData.fat_form,
        'col-md-12 col-lg-6': _vm.formData !== null && _vm.formData.fat_form,
      }},[(_vm.columnTemplate === 'single')?_c('SingleColumn',{attrs:{"config":_vm.config,"json-config":_vm.jsonConfig}}):_vm._e(),(_vm.jsonConfig.slideshow_top !== undefined)?_c('div',[_c('slideshow-top',{attrs:{"json-config":_vm.jsonConfig,"images":_vm.config.slideshow_top}})],1):_vm._e()],1):_vm._e(),(_vm.config.template === 'base_2022')?_c('div',{staticClass:"col-12 left-side",class:{
        'p-0': _vm.jsonConfig.slideshow_top !== undefined,
        'col-lg-8': _vm.formData !== null && !_vm.formData.fat_form,
        'col-md-12 col-lg-6': _vm.formData !== null && _vm.formData.fat_form,
      }},[(
          _vm.jsonConfig !== null && _vm.jsonConfig.content_header_v1 !== undefined
        )?_c('div',[_c('content-header-v1',{attrs:{"config":_vm.jsonConfig.content_header_v1,"bg-mobile":_vm.config.bg_mobile,"content-header-img":_vm.config.content_header_img}})],1):_vm._e(),(
          _vm.jsonConfig !== null && _vm.jsonConfig.content_header_v2 !== undefined
        )?_c('div',[_c('content-header-v2',{attrs:{"config":_vm.jsonConfig.content_header_v2,"bg-mobile":_vm.config.bg_mobile}})],1):_vm._e()]):_vm._e(),(_vm.jsonConfig !== null && _vm.jsonConfig.cta !== undefined)?_c('div',{staticClass:"col-12 mt-md-3 cta-bg pt-lg-3 mb-md-3 text-center d-block d-lg-none",attrs:{"id":"cta1"}},[_c('div',{staticClass:"cta"},[_c('p',{staticClass:"mt-3"},[_c('span',{staticClass:"cta_text_1",domProps:{"innerHTML":_vm._s(_vm.jsonConfig.cta.text1)}}),_c('span',{staticClass:"cta_text_2",domProps:{"innerHTML":_vm._s(_vm.jsonConfig.cta.text2)}})])])]):_vm._e(),(_vm.jsonConfig.container_after_form !== undefined)?_c('div',{staticClass:"container container_after_form d-none",class:{
        'd-block d-md-none':
          _vm.jsonConfig.container_after_form.active_top === true,
      }},_vm._l((_vm.jsonConfig.container_after_form.bullets),function(item,i){return _c('div',{key:i,staticClass:"col-12 col-lg-4 pb-3 pb-md-0",class:{
          'offset-md-4': _vm.jsonConfig.container_after_form.bullets.length === 1,
          'offset-md-2':
            _vm.jsonConfig.container_after_form.bullets.length === 2 && i !== 1,
          'col-lg-6':
            _vm.jsonConfig.container_after_form.size !== undefined &&
            _vm.jsonConfig.container_after_form.size == 2,
        },attrs:{"index":i}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3"},[_c('img',{attrs:{"src":_vm.config.bullets[i],"alt":"","width":"60px"}})]),_c('div',{staticClass:"col-9"},[_c('h4',[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.text))])])])])}),0):_vm._e(),(_vm.config.scroller === 1)?_c('div',{staticClass:"d-block d-md-none",staticStyle:{"width":"100%"}},[_vm._m(0)]):_vm._e(),(_vm.formData !== null && _vm.formData.form_placement === 'top')?_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.visibilityChanged),expression:"visibilityChanged"}],staticClass:"col-12 right-side",class:{
        'col-md-12 col-lg-4': !_vm.formData.fat_form,
        'col-md-12 col-lg-6': _vm.formData.fat_form,
      },attrs:{"id":"form"}},[_c('contact-form',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
          callback: _vm.changeVisible,
          intersection: { threshold: 0.4 },
        }),expression:"{\n          callback: changeVisible,\n          intersection: { threshold: 0.4 },\n        }"}],attrs:{"config":_vm.config,"form-data":_vm.formData,"setup":_vm.setup,"json-config":_vm.jsonConfig}})],1):_vm._e(),(_vm.setup.disclaimer_zone === 'top')?_c('div',{staticClass:"col-12 mt-3 mt-md-3 disclaimer mb-3 mb-md-3"},[_c('p',{attrs:{"id":"privacy"},domProps:{"innerHTML":_vm._s(_vm.privacyText)}}),_c('p',{attrs:{"id":"contract"}},[_c('strong',{domProps:{"innerHTML":_vm._s(_vm.campaignContract)}})]),_c('p',{attrs:{"id":"disclaimer"},domProps:{"innerHTML":_vm._s(_vm.setup.campaign_disclaimer)}})]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center scroller"},[_c('div',{attrs:{"data-aos":"fade-up","data-aos-easing":"ease","data-aos-offset":"0"}},[_c('a',{attrs:{"href":"#form"}},[_c('i',{staticClass:"fas fa-chevron-down fa-3x"})])])])
}]

export { render, staticRenderFns }