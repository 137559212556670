<template>
	<div class="row mt-5 mb-3 tabbed-content" id="tabbed-content-scroll">
		<div class="col-12">
			<b-tabs content-class="mt-3" v-model="tabIndex">
				<b-tab v-for="(tab, i) in items" :key="i" :index="i" :title="tab.title" :id="'viaturas-' + i">
					<div class="row">
						<div class="col-12 mb-4" v-for="(item, indx) in tab.item_data" :key="indx"
							 :index="indx"
							 :class="{'col-lg-6' : parseInt(config.grid) === 2, 'col-lg-4' : parseInt(config.grid) === 3, 'col-lg-12' : parseInt(config.grid) === 1}">
							<div class="d-none d-lg-block">
								<div class="row" v-if="item.image">
									<div class="col-12" v-if="item.image.length === 1">
										<img :src="item.image[0]"
											 class="img-fluid">
									</div>
									<div class="col-6 p-1" v-if="item.image.length > 1 && item.image.length < 3">
										<img :src="item.image[0]"
											 class="img-fluid">
									</div>
									<div class="col-6 p-1" v-if="item.image.length > 1 && item.image.length < 3">
										<img :src="item.image[1]"
											 class="img-fluid">
									</div>
									<div class="col-4 p-1" v-if="item.image.length > 2">
										<img :src="item.image[0]"
											 class="img-fluid">
									</div>
									<div class="col-4 p-1" v-if="item.image.length > 2">
										<img :src="item.image[1]"
											 class="img-fluid">
									</div>
									<div class="col-4 p-1" v-if="item.image.length > 2">
										<img :src="item.image[2]"
											 class="img-fluid">
									</div>
								</div>
							</div>
							<div class="d-block d-lg-none">
								<div class="row">
									<div class="col-12" v-if="item.image.length === 1">
										<img :src="item.image[0]"
											 class="img-fluid">
									</div>
									<div class="col-12" v-if="item.image.length > 1 && isLoaded">
										<hooper id="slider-tabbed-content" :settings="hooperSettings" ref="carousel"
												:infinite-scroll="true">
											<slide v-for="(slide, a) in item.image" :key="a" :index="a">
												<img v-lazy="slide" :alt="slide" class="img-fluid">
											</slide>
											<hooper-navigation slot="hooper-addons"></hooper-navigation>
										</hooper>
									</div>
								</div>
							</div>

							<div class="row mb-4 mt-3 car-make-model" :id="item.version_id">
								<div class="col-12">
									<h2 v-if="item.title">{{ item.title }}</h2>
									<h3 v-if="item.subtitle">{{ item.subtitle }}</h3>
								</div>
							</div>

							<div class="row mt-3 mb-4 price-zone">
								<div class="col-12">
									<div class="d-flex justify-content-between">
										<div>
											<p class="stats-price-title" v-if="item.price">
												Preço
											</p>
											<p class="stats-price">
												{{
													item.price | currency('€', 0, {
														thousandsSeparator : '.',
														symbolOnLeft       : false
													})
												}}<span class="tax"
														v-if="item.tax_text === 1">+IVA</span>
											</p>
											<p class="monthly-fee"
											   v-if="item.subscription_price"
											   v-html="item.subscription_price+'€/mês'">
											</p>
										</div>
									</div>
								</div>
							</div>

							<div class="row mb-2">
								<div class="col-6 mb-2" v-if="item.hp">
									<p>Potência</p>
									<p class="stats">
										{{ item.hp }} <span>cv</span>
									</p>
								</div>
								<div class="col-6 mb-2" v-if="item.fuel">
									<p>Combustível</p>
									<p class="stats" v-html="item.fuel">
									</p>
								</div>
								<div class="col-6 mb-2" v-if="item.consumption">
									<p>Consumo médio</p>
									<p class="stats">
										{{ item.consumption }} <span>l/100km</span>
									</p>
								</div>
								<div class="col-6 mb-2" v-if="item.electric_consumption">
									<p>Consumo elétrico</p>
									<p class="stats">
										{{ item.electric_consumption }} <span>kWh/100km</span>
									</p>
								</div>
								<div class="col-6 mb-2" v-if="item.range">
									<p>Autonomia</p>
									<p class="stats">
										{{ item.range }} <span>km</span>
									</p>
								</div>
								<div class="col-6 mb-2" v-if="item.emission">
									<p>Emissões CO2</p>
									<p class="stats">
										{{ item.emission }} <span>g/km</span>
									</p>
								</div>
							</div>

							<div class="row mt-3 mb-4" v-if="item.description">
								<div class="col-12">
									<h6>Principais características</h6>
									<div class="specs">
										<div v-html="item.description"></div>
									</div>
								</div>
							</div>

							<div class="row d-flex justify-content-center">
								<div class="">
									<div v-if="item.form_id !== null">
										<p class="select-car">
											<a :href="item.link" :target="setBlank(item.link)" rel="noopener"
											   @click="changeVersion(item.form_id)"
											   style="padding-top:15px; font-weight: 400; font-size: 0.9rem;"
											   v-html="config.detail_text_link">
												<img class="pl-1" v-lazy="config.button_img" alt=""
													 v-if="config.button_img !== undefined && config.button_img !== ''"
													 height="17px"></a>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</b-tab>
			</b-tabs>
		</div>
	</div>
</template>

<script>

import {BTab, BTabs}                                   from 'bootstrap-vue';
import {Hooper, Navigation as HooperNavigation, Slide} from 'hooper';

export default {
	name       : 'ProductsCardsV3',
	components : {
		Hooper,
		Slide,
		HooperNavigation,
		BTabs,
		BTab
	},
	props      : {
		config   : {
			type     : Object,
			required : true
		},
		products : {
			type     : Array,
			required : true
		}
	},
	data() {
		return {
			items          : [],
			tabIndex       : 0,
			hooperSettings : {
				itemsToShow  : 1,
				mouseDrag    : false,
				centerMode   : true,
				wheelControl : false,
			},
			isLoaded       : false
		};
	},
	watch : {
		tabIndex : function (val) {
			if (this.$refs.carousel !== undefined) {
				this.$refs.carousel.forEach((e) => {
					e.restart();
				});
			}
		}
	},
	mounted() {
		this.items = this.setTabs();

		if (this.$route.hash.indexOf('#viaturas-') > -1) {
			setTimeout(() => {
				let hashTag   = this.$route.hash.split('?');
				let toSplit   = hashTag[0].split('-');
				this.tabIndex = parseInt(toSplit[1]);
				let id        = `${toSplit[0].replace('#', '')}-${toSplit[1]}`;
				document.getElementById(`tabbed-content-scroll`).scrollIntoView();
				document.getElementById(`${id}___BV_tab_button__`).scrollIntoView();
			}, 500);
		}

		setTimeout(() => {
			this.isLoaded = true;
		}, 500);
	},
	methods : {
		setTabs() {
			let tabs = [];
			this.config.tab_names.forEach((e) => {
				let tab       = {};
				tab.tag       = e.tag;
				tab.title     = e.title;
				tab.item_data = this.products.filter((el) => {
					let tags = el.tags.replace(' ', '').split(',');
					return tags.includes(e.tag);
				});
				tabs.push(tab);

			});

			return tabs;
		},
		setImg(img) {
			if (process.env.NODE_ENV === 'development') {
				return 'https://www.rotauto.com/assets/img/imagemOG_rotauto2020.jpg';
			}
			return `${img}`;
		},
		setBlank(link) {
			if (link === undefined || link === null || (link.indexOf('#') > -1 && link.indexOf('http') < 0)) {
				return '_self';
			}
			return '_blank';
		},
		changeVersion(val) {
			if (val !== undefined) {
				this.$eventBus.$emit('change-version', val);
			}
		}
	}
};
</script>
