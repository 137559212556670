<template>
  <header class="soauto-vgrp">
    <nav class="navbar navbar-light bg-light">
      <div class="container">
        <a class="navbar-brand">
          <img
            class="logo-left"
            src="https://www.soautovgrp.pt/@@poi.imageproxy/54cbb19ecdd44bf49cc5c549d1e0769c/dealer-logo.svg"
            alt="Soauto VGRP"
            width="100%"
          />
        </a>
      </div>
    </nav>
  </header>
</template>

<script>
import "@/assets/styles/scss/_header-soauto-vgrp.scss";
export default {
  name: "HeaderSoautoVGRP",
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      logoRightSrc: this.config.logo_right,
      logoRightAlt: this.config.nav_logo_right_alt,
      logoLeftSrc: this.config.logo_left,
      logoLeftAlt: this.config.nav_logo_left_alt,
      logoLink: this.config.logo_link,
    };
  },
  methods: {
    setImgFallback(img) {
      const { userAgent } = navigator;

      if (userAgent.includes("Firefox/")) {
        return img;
      } else if (userAgent.includes("Edg/")) {
        return img;
      } else if (userAgent.includes("Chrome/")) {
        return img;
      } else if (userAgent.includes("Safari/")) {
        return img.replace("/conversions", "").replace(".webp", ".png");
      }

      return img;
    },
  },
};
</script>
