<template>
	<div class="row mt-5 mb-3 tabbed-content" id="tabbed-content-scroll">
		<div class="col-12">
			<b-tabs content-class="mt-3" v-model="tabIndex">
				<b-tab v-for="(tab, i) in cars" :key="i" :index="i" :title="tab.title" :id="'viaturas-' + i">
					<div class="row">
						<div class="col-12 col-md-6 mb-4" v-for="(car, indx) in tab.car_data" :key="indx"
							 :index="indx"
							 :class="{'col-lg-6' : config.grid === 2, 'col-lg-4' : config.grid === 3, 'col-lg-12' : config.grid === 1}">
							<div class="row" v-if="car.imgs.length === 1">
								<div class="col-12">
									<img v-lazy="setImg(car.imgs[0].src)" :alt="car.imgs[0].alt"
										 class="img-fluid">
								</div>
							</div>

							<div class="row" v-if="car.imgs.length > 1 && isLoaded">
								<div class="col-12">
									<hooper id="slider-tabbed-content" :settings="hooperSettings" ref="carousel"
											:infinite-scroll="true">
										<slide v-for="(slide, a) in car.imgs" :key="a" :index="a">
											<img v-lazy="slide.src" :alt="slide.alt" class="img-fluid">
										</slide>
										<hooper-navigation slot="hooper-addons"></hooper-navigation>
									</hooper>
								</div>
							</div>

							<div class="row mb-4 mt-3 car-make-model" :id="car.version_id">
								<div class="col-12">
									<h2>{{ car.make_model }}</h2>
									<h3>{{ car.version }}</h3>
								</div>
							</div>

							<div class="row mt-3 mb-4 price-zone">
								<div class="col-12">
									<div class="d-flex justify-content-between">
										<div>
											<p class="stats-price-title">
												Preço
											</p>
											<p class="stats-price">
												{{
													car.price | currency('€', 0, {
														thousandsSeparator : '.',
														symbolOnLeft       : false
													})
												}}<span class="tax"
														v-if="car.show_tax_text && car.show_tax_text !== undefined">+IVA</span>
											</p>
											<p class="monthly-fee"
											   v-if="car.sub_price !== undefined && car.sub_price!== '' "
											   v-html="car.sub_price">
											</p>
										</div>
										<div>
											<p class="select-car">
												<a :href="car.link" :target="setBlank(car.link)" rel="noopener"
												   @click="changeVersion(car.version_id)"
												   style="padding-top:15px; font-weight: 400; font-size: 0.9rem;">{{
														config.detail_text_link
													}}
													<img class="pl-1" v-lazy="config.button_img" alt=""
														 v-if="config.button_img !== undefined && config.button_img !== ''"
														 height="17px"></a>
											</p>
										</div>
									</div>
								</div>
							</div>

							<div class="row mb-2">
								<div class="col-6 mb-2">
									<p>Potência</p>
									<p class="stats" v-html="car.hp">
									</p>
								</div>
								<div class="col-6 mb-2">
									<p>Combustível</p>
									<p class="stats" v-html="car.fuel">
									</p>
								</div>
								<div class="col-6 mb-2" v-if="car.consumption !== undefined">
									<p>Consumo médio</p>
									<p class="stats" v-html="car.consumption">
									</p>
								</div>
								<div class="col-6 mb-2" v-if="car.electric_consumption !== undefined">
									<p>Consumo elétrico</p>
									<p class="stats" v-html="car.electric_consumption">
									</p>
								</div>
								<div class="col-6 mb-2" v-if="car.range !== undefined">
									<p>Autonomia</p>
									<p class="stats" v-html="car.range">
									</p>
								</div>
								<div class="col-6 mb-2">
									<p>Emissões CO2</p>
									<p class="stats" v-html="car.emissions">
									</p>
								</div>
							</div>

							<div class="row mt-3 mb-4" v-if="car.specs !== undefined && car.specs.length > 0">
								<div class="col-12">
									<h6>Principais características</h6>
									<ul class="specs">
										<li v-for="(spec, a) in car.specs" :key="a" :index="a">
											{{ spec }}
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</b-tab>
			</b-tabs>
		</div>
	</div>
</template>

<script>

import {BTab, BTabs}                                   from 'bootstrap-vue';
import {Hooper, Navigation as HooperNavigation, Slide} from 'hooper';

export default {
	name       : 'TabbedStaticContentV2',
	components : {
		Hooper,
		Slide,
		HooperNavigation,
		BTabs,
		BTab
	},
	props      : {
		config : {
			type     : Object,
			required : true
		}
	},
	data() {
		return {
			cars           : [],
			tabIndex       : 0,
			hooperSettings : {
				itemsToShow  : 1,
				mouseDrag    : false,
				centerMode   : true,
				wheelControl : false,
			},
			isLoaded       : false
		};
	},
	watch : {
		tabIndex : function (val) {
			if (this.$refs.carousel !== undefined) {
				this.$refs.carousel.forEach((e) => {
					e.restart();
				});
			}
		}
	},
	mounted() {
		this.cars = this.setTabs();

		if (this.$route.hash.indexOf('#viaturas-') > -1) {
			setTimeout(() => {
				let hashTag   = this.$route.hash.split('?');
				let toSplit   = hashTag[0].split('-');
				this.tabIndex = parseInt(toSplit[1]);
				let id        = `${toSplit[0].replace('#', '')}-${toSplit[1]}`;
				document.getElementById(`tabbed-content-scroll`).scrollIntoView();
				document.getElementById(`${id}___BV_tab_button__`).scrollIntoView();
			}, 500);
		}

		setTimeout(() => {
			this.isLoaded = true;
		}, 500);
	},
	methods : {
		setTabs() {
			let tabs = [];
			this.config.tab_names.forEach((e) => {
				let tab      = {};
				tab.tag      = e.tag;
				tab.title    = e.title;
				tab.car_data = this.config.car_data.filter((el) => {
					return el.tags.includes(e.tag);
				});
				tabs.push(tab);
			});

			return tabs;
		},
		setImg(img) {
			if (process.env.NODE_ENV === 'development') {
				return 'https://www.rotauto.com/assets/img/imagemOG_rotauto2020.jpg';
			}
			return `${img}`;
		},
		setBlank(link) {
			if (link === undefined || (link.indexOf('#') > -1 && link.indexOf('http') < 0)) {
				return '_self';
			}
			return '_blank';
		},
		changeVersion(val) {
			if (val !== undefined) {
				this.$eventBus.$emit('change-version', val);
			}
		},
		setUrl(e, url) {
			if (url.indexOf('#') > -1) {

			}
		}
	}
};
</script>
