var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-5 mb-3 tabbed-content",attrs:{"id":"tabbed-content-scroll"}},[_c('div',{staticClass:"col-12"},[_c('b-tabs',{attrs:{"content-class":"mt-3"},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},_vm._l((_vm.items),function(tab,i){return _c('b-tab',{key:i,attrs:{"index":i,"title":tab.title,"id":'viaturas-' + i}},_vm._l((tab.item_data),function(item,indx){return _c('div',{key:indx,staticClass:"row mb-4",attrs:{"index":indx}},[_c('div',{staticClass:"col-12 col-md-6 align-self-center",class:{
              'order-md-2 order-1': !(indx % 2),
              'order-md-1 order-2': _vm.config.ordered !== undefined,
            }},[_c('div',{staticClass:"row mb-4 mt-3 car-make-model",attrs:{"id":item.version_id}},[_c('div',{staticClass:"col-12"},[(item.title)?_c('h2',{staticClass:"custom-title",domProps:{"innerHTML":_vm._s(item.title)}}):_vm._e(),(item.subtitle)?_c('h3',{staticClass:"custom-subtitle",domProps:{"innerHTML":_vm._s(item.subtitle)}}):_vm._e(),(item.price)?_c('h3',{staticClass:"custom-price",domProps:{"innerHTML":_vm._s(item.price)}}):_vm._e()])]),(item.description)?_c('div',{staticClass:"row mt-3 mb-4"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"specs custom-desc"},[_c('div',{domProps:{"innerHTML":_vm._s(item.description)}})]),(item.form_id !== null)?_c('div',{staticClass:"mt-4"},[_c('p',{staticClass:"select-car custom-btn"},[_c('a',{staticStyle:{"font-weight":"400","font-size":"1rem","text-decoration":"none"},attrs:{"href":item.link,"target":_vm.setBlank(item.link),"rel":"noopener"},domProps:{"innerHTML":_vm._s(_vm.config.detail_text_link)},on:{"click":function($event){return _vm.changeVersion(item.form_id)}}},[(
                          _vm.config.button_img !== undefined &&
                          _vm.config.button_img !== ''
                        )?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.config.button_img),expression:"config.button_img"}],staticClass:"pl-1",attrs:{"alt":"","height":"17px"}}):_vm._e()])])]):_vm._e()])]):_vm._e()]),_c('div',{staticClass:"col-12 col-md-6",class:{ 'order-md-1 order-1': !(indx % 2) }},[(item.image)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('img',{staticClass:"img-fluid custom-img",attrs:{"src":item.image[0]}})])]):_vm._e()])])}),0)}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }