<template>
	<footer class="footer footer-dacia" id="footer">
		<cookie-law theme="mytheme" :position="'bottom'" :transition-name="'fade'" ref="cookieLaw"
					style="color: rgb(0, 0, 0); background-color: rgb(255, 204, 0); visibility: visible;"
					v-if="timeout">
			<div class="cookie-notice-container">
				<div class="title">
					Funcionamento dos cookies
				</div>
				<div>
                    <span id="cn-notice-text">O nosso Website e parceiros utilizam cookies para medir a audiência e o desempenho do Website. Poderá, em qualquer altura, alterar as opções escolhidas, acedendo à secção "Gerir cookies" do nosso Website. Para saber mais, consulte a nossa <a
						href="https://renault.santogal.pt/dacia/sobre-nos/politica-de-privacidade.html"
						target="_blank" rel="noopener">política de cookies</a>.</span>
				</div>
				<div class="pt-3 d-md-flex justify-content-md-start">
					<div class="d-block d-md-none">
					<a id="cn-accept-cookie"
					   class="cn-set-cookie cn-button bootstrap button" @click="setCookies">Aceitar
						Cookies</a>
					</div>
					<div>
						<button id="cn-dismiss-cookie" class="cookie-notice-dismiss" @click="setCookies">Rejeitar</button>
					</div>
					<div class="d-none d-md-block" style="margin-top:10px;">
						<a id="cn-accept-cookie"
						   class="cn-set-cookie cn-button bootstrap button" @click="setCookies">Aceitar
							Cookies</a>
					</div>
				</div>
			</div>
		</cookie-law>
		<div class="container-fluid footer-middle">
			<div class="container">
				<div class="row">
					<div class="col-12 col-md-6 col-lg-3 mb-4">
						<div class="row">
							<div class="col-12">
								<a href="https://renault.santogal.pt/dacia/sobre-nos/politica-de-privacidade/#quemSomos"
								   target="_blank"
								   rel="noopener">Sobre
									nós</a>
							</div>
							<div class="col-12">
								<a href="https://renault.santogal.pt/dacia/encontre-um-concessionario/" target="_blank"
								   rel="noopener">Encontre
									o seu
									concessionário</a>
							</div>
							<div class="col-12">
								<a href="https://renault.santogal.pt/dacia/test-drive/" target="_blank" rel="noopener">Test-drive</a>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-6 col-lg-3 mb-4">
						<div class="row">
							<div class="col-12">
								<a href="https://renault.santogal.pt/dacia/veiculos-novos/" target="_blank" rel="noopener">Veículos
									Novos</a>
							</div>
							<div class="col-12">
								<a href="https://renault.santogal.pt/dacia/veiculos-usados/" target="_blank" rel="noopener">Veículos
									Usados</a>
							</div>
							<div class="col-12">
								<a href="https://renault.santogal.pt/dacia/taxi-dacia/" target="_blank"
								   rel="noopener">Taxi Dacia</a>
							</div>
							<div class="col-12">
								<a href="https://renault.santogal.pt/dacia/acessorios-oficina/" target="_blank"
								   rel="noopener">Oficina</a>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-6 col-lg-3 mb-4">
						<div class="row">
							<div class="col-12">
								<a href="https://renault.santogal.pt/dacia/sobre-nos/politica-de-privacidade/#oqueeumCookie"
								   target="_blank" rel="noopener">
									Cookies</a>
							</div>
							<div class="col-12">
								<a href="https://renault.santogal.pt/dacia/sobre-nos/politica-de-privacidade/"
								   target="_blank" rel="noopener">Privacidade</a>
							</div>
							<div class="col-12">
								<a href="https://renault.santogal.pt/dacia/encontre-um-concessionario/" target="_blank"
								   rel="noopener">Contacte-nos</a>
							</div>
						</div>
					</div>
					<div class="col-12 col-md-6 col-lg-3 mb-4">
						<div class="row">
							<div class="col-12 footerSocialTitle">
								<span style="color: #fff; font-size: 14px; font-weight: 600;">Ligue-se a nós</span>
							</div>
							<div class="col-12">
								<div class="row">
									<div class="col-1">
										<i class="fa-brands fa-facebook-f"></i>
									</div>
									<div class="col-10">
										<a class="single-media-link"
										   href="https://www.facebook.com/SantogalRenaultDacia"
										   target=" _blank"
										   rel="noopener noreferrer"
										   data-title="Facebook">Facebook</a>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="row">
									<div class="col-1">
										<i class="fa-brands fa-instagram"></i>
									</div>
									<div class="col-10">
										<a class="single-media-link" title="Instagram"
										   href="https://www.instagram.com/santogalrenaultdacia/" target=" _blank"
										   rel="noopener noreferrer" data-title="Instagram">Instagram</a>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="row">
									<div class="col-1">
										<i class="fa-brands fa-linkedin-in"></i>
									</div>
									<div class="col-10">
										<a class="single-media-link" title="Linkedin"
										   href="https://www.linkedin.com/company/santogalrenaultedacia/"
										   target=" _blank" rel="noopener noreferrer" data-title="Linkedin">Linkedin</a>
									</div>
								</div>
							</div>
							<div class="col-12">
								<div class="row">
									<div class="col-1">
										<i class="fa-brands fa-youtube"></i>
									</div>
									<div class="col-10">
										<a class="single-media-link"
										   title="Youtube"
										   href="https://www.youtube.com/channel/UC0IM2ShCJgNI-x6e52eyK_Q"
										   target=" _blank"
										   rel="noopener noreferrer"
										   data-title="Youtube">Youtube</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid footer-bottom">
			<div class="container">
				<div class="row">
					<div class="col-12 d-lg-flex justify-content-start">
						<p>&copy; {{ year }} Santogal All rights reserved</p>
						<a href="https://renault.santogal.pt/dacia/sobre-nos/politica-de-privacidade/" target="_blank"
						   rel="noopener">Política
							de
							Privacidade</a>
						<a href="https://www.livroreclamacoes.pt/apoio-utilizador" target="_blank" rel="noopener">Livro
							Reclamações Eletrónico</a>
						<a href="https://www.consumidor.gov.pt/" target="_blank" rel="noopener">
							Gestão de Conflitos (RAL)</a>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import '@/assets/styles/scss/_footer-dacia.scss';
import footerMixinUtils from '@/common/footer-utils';
import * as moment      from 'moment';
import CookieLaw        from 'vue-cookie-law';

export default {
	name       : 'FooterSantogalDacia',
	components : { CookieLaw },
	mixins     : [footerMixinUtils],
	props      : {
		config   : {
			type     : Object,
			required : true,
		},
		setup    : {
			type     : Object,
			required : true,
		},
		sellerId : {
			type     : Number,
			required : true
		}
	},
	data() {
		return {
			year    : moment().year(),
			url     : this.setup.company_url,
			name    : this.config.company !== undefined ? this.config.company.name : this.setup.company_name,
			mobile  : this.isMobile(),
			timeout : false,
		};
	},
	created() {
		this.setStyles();
	},
	mounted() {
		if (this.$cookies.get(`${this.sellerId}-autoleads-cookie`) !== null && this.$cookies.get(`${this.sellerId}-autoleads-cookie`).accepted && this.$cookies.get(`${this.sellerId}-autoleads-cookie`).domain === window.location.hostname) {
			setTimeout(() => {
				if (this.$refs.cookieLaw !== undefined) this.$refs.cookieLaw.close();
			}, 500);
		} else {
			this.timeout = true;
			setTimeout(() => {
				this.$refs.cookieLaw.open();
			}, 500);
		}
	},
	methods : {
		redirect() {
			window.location.href = this.setup.company_url;
		},
		setCookies() {
			this.$refs.cookieLaw.close();
			this.$eventBus.$emit('set-cookies-gtm', true);
		},
		setStyles() {
			const styles   = document.getElementById('injected-styles-other');
			const textnode = document.createTextNode(`

				body {font-family: "DaciaBlock", Helvetica, Arial, sans-serif !important;}

			`);
			styles.appendChild(textnode);
		},
	}
};
</script>
