<template>
  <footer>
    <cookie-law
      theme="mytheme"
      :position="mobile ? 'bottom' : 'top'"
      :transition-name="'fade'"
      ref="cookieLaw"
      v-if="timeout"
    >
      <div style="width: 100%; padding: 30px; font-size: 0.85rem">
        <div style="max-width: 1140px; margin: 0 auto">
          <h2
            style="font-size: 1.5rem"
            class="pb-3 pt-2"
            v-if="cookiesText !== ''"
          >
            {{ cookiesTitle }}
          </h2>
          <p style="line-height: 1.5rem" v-html="setCookiesText()"></p>
          <p class="text-right">
            <a
              class="ml-3"
              @click="redirect()"
              style="font-size: 0.85rem; font-weight: 400; cursor: pointer"
            >
              <span>Não Aceito</span>
            </a>
            <button
              class="ml-3 btn btn-primary"
              @click="setCookies"
              style="font-size: 14px; font-weight: 700; padding: 5px 10px"
            >
              <span>Aceito</span>
            </button>
          </p>
        </div>
      </div>
    </cookie-law>
    <div class="container-fluid footer-soauto-vgrp">
      <div class="container">
        <div class="row">
          <div class="col-lg-2 col-12">
            <h4>Contacto</h4>
            <h5>SOAUTO VGRP</h5>
		  <a
			  href="https://www.soautovgrp.pt/contactos/lisboa"
			  target="_blank"
			  class="d-block mb-1"
		  >Lisboa</a
		  >
            <a
              href="https://www.soautovgrp.pt/contactos/barreiro"
              target="_blank"
              class="d-block mb-1"
              >Barreiro</a
            >
            <a
              href="https://www.soautovgrp.pt/contactos/carnaxide"
              target="_blank"
              class="d-block mb-1"
              >Carnaxide</a
            >
            <a
              href="https://www.soautovgrp.pt/contactos/loures"
              target="_blank"
              class="d-block mb-1"
              >Loures</a
            >
            <a
              href="https://www.soautovgrp.pt/contactos/cascais"
              target="_blank"
              class="d-block mb-1"
              >Cascais</a
            >
            <a
              href="https://www.soautovgrp.pt/contactos/av-republica"
              target="_blank"
              class="d-block mb-1"
              >Av. Republica</a
            >
            <a
              href="https://www.soautovgrp.pt/contactos/porto"
              target="_blank"
              class="d-block mb-1"
              >Porto</a
            >
            <a
              href="https://www.soautovgrp.pt/contactos/porto-usados"
              target="_blank"
              class="d-block mb-1"
              >Porto Usados</a
            >
            <a
              href="https://www.soautovgrp.pt/contactos/cupra-city-garage"
              target="_blank"
              class="d-block mb-1"
              >CUPRA City Garage</a
            >
          </div>
          <div class="col-lg-2 col-12">
            <div class="accordion" role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    v-b-toggle.accordion-1
                    variant="info"
                    class="title-accordion"
                    ><h4>As nossas marcas</h4>
                    <span
                      ><i
                        class="fas fa-chevron-down d-lg-none d-block"
                      ></i></span
                  ></b-button>
                </b-card-header>
                <b-collapse
                  id="accordion-1"
                  v-model="collapseStates[0]"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text
                      ><a
                        href="https://www.soautovgrp.pt/marcas/volkswagen"
                        target="_blank"
                        class="d-block mb-1"
                        >Volkswagen</a
                      >
                      <a
                        href="https://www.soautovgrp.pt/marcas/audi"
                        class="d-block mb-1"
                        target="_blank"
                        >Audi</a
                      >
                      <a
                        href="https://www.soautovgrp.pt/marcas/skoda"
                        target="_blank"
                        class="d-block mb-1"
                        >ŠKODA</a
                      >
                      <a
                        href="https://www.soautovgrp.pt/marcas/cupra"
                        target="_blank"
                        class="d-block mb-1"
                        >CUPRA</a
                      >
                      <a
                        href="https://www.soautovgrp.pt/marcas/veiculos-comerciais-volkswagen"
                        target="_blank"
                        class="d-block mb-1"
                        >Veículos Comerciais Volkswagen</a
                      >
                      <a
                        href="https://www.dasweltauto.pt/gruppe/soautovgrp/SOAUROVGRP/13011/search"
                        target="_blank"
                        class="d-block mb-1"
                        >Das WeltAuto</a
                      ></b-card-text
                    >
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
          <div class="col-lg-2 col-12">
            <div class="accordion" role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    v-b-toggle.accordion-2
                    variant="info2"
                    class="title-accordion"
                    ><h4>Carros novos</h4>
                    <span
                      ><i
                        class="fas fa-chevron-down d-lg-none d-block"
                      ></i></span
                  ></b-button>
                </b-card-header>
                <b-collapse
                  id="accordion-2"
                  v-model="collapseStates[1]"
                  accordion="my-accordion2"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text
                      ><a
                        href="https://www.soautovgrp.pt/carros-novos/imediatamente-disponivel"
                        target="_blank"
                        class="d-block mb-1"
                        >Imediatamente disponível</a
                      >
                      <a
                        href="https://www.soautovgrp.pt/carros-novos/test-drive"
                        target="_blank"
                        class="d-block mb-1"
                        >Test drive</a
                      >
                      <a
                        href="https://www.soautovgrp.pt/carros-novos/mobilidade-eletrica"
                        target="_blank"
                        class="d-block mb-1"
                        >Mobilidade elétrica</a
                      >
                      <a
                        href="https://www.soautovgrp.pt/carros-novos/ofertas-promocoes"
                        target="_blank"
                        class="d-block mb-1"
                        >Ofertas & Promoções</a
                      >
                      <a
                        href="https://www.soautovgrp.pt/carros-novos/configurar"
                        target="_blank"
                        class="d-block mb-1"
                        >Configurar</a
                      ></b-card-text
                    >
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
          <div class="col-lg-2 col-12">
            <div class="accordion" role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    v-b-toggle.accordion-3
                    variant="info3"
                    class="title-accordion"
                    ><h4>Carros usados</h4>
                    <span
                      ><i
                        class="fas fa-chevron-down d-lg-none d-block"
                      ></i></span
                  ></b-button>
                </b-card-header>
                <b-collapse
                  id="accordion-3"
                  v-model="collapseStates[2]"
                  accordion="my-accordion3"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <a
                        href="https://www.dasweltauto.pt/gruppe/soautovgrp/SOAUROVGRP/13011/search"
                        target="_blank"
                        class="d-block mb-1"
                        >Das WeltAuto</a
                      >
                      <a
                        href="https://www.soautousados.pt/"
                        class="d-block mb-1"
                        target="_blank"
                        >Soauto Usados</a
                      >
                      <a
                        href="https://www.outletcars.pt/"
                        class="d-block mb-1"
                        target="_blank"
                        >Outletcars</a
                      >
                      <a
                        href="https://www.soautousados.pt/Campanhas"
                        class="d-block mb-1"
                        target="_blank"
                        >Campanhas</a
                      >
                      <a
                        href="https://www.soautousados.pt/Quer-Vender-o-seu-carro"
                        target="_blank"
                        class="d-block mb-1"
                        >Compramos o seu carro</a
                      >
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
          <div class="col-lg-2 col-12">
            <div class="accordion" role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    v-b-toggle.accordion-4
                    variant="info4"
                    class="title-accordion"
                    ><h4>Serviços e Acessórios</h4>
                    <span
                      ><i
                        class="fas fa-chevron-down d-lg-none d-block"
                      ></i></span
                  ></b-button>
                </b-card-header>
                <b-collapse
                  id="accordion-4"
                  v-model="collapseStates[3]"
                  accordion="my-accordion4"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <a
                        href="https://www.soautovgrp.pt/servicos-e-acessorios/campanhas-e-ofertas"
                        target="_blank"
                        class="d-block mb-1"
                        >Campanhas e Ofertas</a
                      >
                      <a
                        href="https://www.soautovgrp.pt/servicos-e-acessorios/marcacao-de-oficina"
                        target="_blank"
                        class="d-block mb-1"
                        >Marcação de oficina</a
                      >
                      <a
                        href="https://www.soautovgrp.pt/servicos-e-acessorios/pneus-e-jantes"
                        target="_blank"
                        class="d-block mb-1"
                        >Pneus e jantes</a
                      >
                      <a
                        href="https://www.soautovgrp.pt/servicos-e-acessorios/acessorios-para-veiculos"
                        target="_blank"
                        class="d-block mb-1"
                        >Acessórios para veículos</a
                      >
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
          <div class="col-lg-2 col-12">
            <div class="accordion" role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    v-b-toggle.accordion-5
                    variant="info5"
                    class="title-accordion"
                    ><h4>Recrutamento</h4>
                    <span
                      ><i
                        class="fas fa-chevron-down d-lg-none d-block"
                      ></i></span
                  ></b-button>
                </b-card-header>
                <b-collapse
                  id="accordion-5"
                  v-model="collapseStates[4]"
                  accordion="my-accordion5"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <a
                        href="https://www.soautovgrp.pt/empregos#Ofertas%20de%20trabalho"
                        target="_blank"
                        class="d-block mb-1"
                        >Vagas</a
                      >
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid subfooter-soauto-vgrp">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <ul class="contact-links">
              <li>
                <a
                  href="https://www.soautovgrp.pt/avisos-legais-compliance-e-integridade"
                  target="_blank"
                >
					Avisos Legais | Compliance e Integridade
                </a>
              </li>
              <li>
                <a
                  href="https://www.soautovgrp.pt/politica-de-cookies"
                  target="_blank"
                >
                  Política de cookies
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" target="_blank">
                  Definição de cookies
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import "@/assets/styles/scss/_footer-soauto-vgrp.scss";
import footerMixinUtils from "@/common/footer-utils";
import * as moment from "moment";
import CookieLaw from "vue-cookie-law";
import { BButton } from "bootstrap-vue";

export default {
  name: "FooterSoauto",
  components: { CookieLaw, BButton },
  mixins: [footerMixinUtils],
  props: {
    config: {
      type: Object,
      required: true,
    },
    setup: {
      type: Object,
      required: true,
    },
    sellerId: {
      type: Number,
      required: true,
    },
    forcePrivacyPages: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      year: moment().year(),
      url: this.setup.company_url,
      name: this.setup.company_name,
      logo: this.setup.footer_logo_agency,
      mobile: this.isMobile(),
      timeout: false,
      cookiesText: this.setup.cookies_text,
      cookiesTitle:
        this.setup.cookies_title !== null
          ? this.setup.cookies_title
          : "Política de Cookies",
      collapseStates: [true, true, true, true, true],
    };
  },
  created() {
    this.setStyles();
  },
  mounted() {
    if (
      this.$cookies.get(`${this.sellerId}-autoleads-cookie`) !== null &&
      this.$cookies.get(`${this.sellerId}-autoleads-cookie`).accepted &&
      this.$cookies.get(`${this.sellerId}-autoleads-cookie`).domain ===
        window.location.hostname
    ) {
      setTimeout(() => {
        if (this.$refs.cookieLaw !== undefined) this.$refs.cookieLaw.close();
      }, 500);
    } else {
      this.timeout = true;
      setTimeout(() => {
        this.$refs.cookieLaw.open();
      }, 500);
    }
    this.isMobile();
    this.onScreenResize();
  },
  methods: {
    setCookiesText() {
      return this.helpers.setCookiesText(
        this.sellerId,
        this.cookiesText,
        this.config
      );
    },
    redirect() {
      window.location.href = this.setup.company_url;
    },
    setCookies() {
      this.$refs.cookieLaw.close();
      this.$eventBus.$emit("set-cookies-gtm", true);
    },
    expandAll() {
      this.collapseStates = this.collapseStates.map((x) => true);
    },
    collapseAll() {
      this.collapseStates = this.collapseStates.map((x) => false);
    },
    onScreenResize() {
      window.addEventListener("resize", () => {
        this.isMobile();
      });
    },
    isMobile() {
      if (window.innerWidth < 991) {
        this.collapseStates = [false, false, false, false, false];
      } else {
        this.collapseStates = [true, true, true, true, true];
      }
    },
    setStyles() {
      const styles = document.getElementById("injected-styles-other");
      const textnode = document.createTextNode(`

				body {font-family: "Roboto", Helvetica, Arial, sans-serif !important; margin-bottom:0 !important;}

			`);
      styles.appendChild(textnode);
    },
  },
};
</script>
