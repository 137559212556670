<template>
	<div class="content-header-v2 row">
		<div class="col-12 mt-5 mt-lg-0 mb-0 mb-lg-3 image-top p-0">
			<img :src="setImgFallback(bgMobile)"
				 class="img-fluid d-block d-lg-none" alt="">
		</div>
		<div class="content row">
			<div class="col-12">
				<div class="row">
					<div class="col-7">
						<h2 v-html="config.main_title"></h2>
					</div>
					<div class="col-5">
						<p class="before-price" v-html="config.text_before_price"></p>
					</div>
				</div>
				<div class="row">
					<div class="col-7">
						<h3 v-html="config.sub_title"></h3>
					</div>
					<div class="col-5">
						<p class="price" v-html="config.price_text"></p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import setImgFallbackMixin from '@/common/set-img-fallback-mixin';

export default {
	name       : 'ContentHeaderV2',
	components : {},
	mixins     : [setImgFallbackMixin],
	props      : {
		bgMobile : {
			type     : Object,
			required : true,
		},
		config   : {
			type     : Object,
			required : true,
		}
	},
	data() {
		return {
			grid : this.size,
		};
	},
};
</script>
