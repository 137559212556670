<template>
	<div class="row">
		<div class="col-12 mt-5 mt-lg-0 mb-0 mb-lg-3 image-top p-0">
			<img v-if="config.image_desktop_responsive === 1" :src="setImgFallback(config.bg_desktop)"
				 class="img-fluid" alt="" :class="{'d-none d-lg-block' : config.image_desktop_responsive === 1}">
			<img :src="setImgFallback(config.bg_mobile)"
				 class="img-fluid d-block d-lg-none" alt="">
		</div>

		<div class="col-12 top" v-if="config.single_column_html !== null" v-html="config.single_column_html"></div>

		<div class="col-12 bullets"
			 v-if="jsonConfig.bullets !== undefined && jsonConfig.bullets.text.length > 0">
			<p v-for="item in jsonConfig.bullets.text">
				<img :src="config.bullet" alt=""><span v-html="item"></span>
			</p>
		</div>
	</div>
</template>

<script>
import setImgFallbackMixin from '@/common/set-img-fallback-mixin';

export default {
	name       : 'SingleColumn',
	components : {},
	mixins     : [setImgFallbackMixin],
	props      : {
		config     : {
			type     : Object,
			required : true,
		},
		jsonConfig : {
			type     : Object,
			required : true,
		}
	}
};
</script>
